import React from 'react';
import Layout from '../components/Layout';
import Metas from "../components/Metas";
import Title from "../components/Title";
import CustomBreadcrumb from "../components/CustomBreadcrumb";

const DesinscriptionSMS = ({ pageContext }) => {
    const {
        breadcrumb: {crumbs},
    } = pageContext

    return (
        <Layout>
            <Metas title={"Desinscription aux alertes SMS"}/>
            <section className="section page-breadcrumb is-small has-background-light">
                <div className="container">
                    <CustomBreadcrumb crumbs={crumbs}/>
                </div>
            </section>
            <section className="section page-content">
                <div className="container">
                    <Title title={"Desinscription aux alertes SMS"} className={"has-text-centered"}/>
                    <iframe
                        width="540"
                        height="356"
                        src="https://414ac9b5.sibforms.com/serve/MUIEAANzD8Bn45P-2sMtPOg5GVk518gG36rV7uj4X9VDFMToFXLj_vGK9yWSM2DXUYuCRzy-wzXEvqL2L2ovc7pw96EH73qBDd9PrT3RUQVtgnwaQKDYyjr0_0ChitL0Ko38j-DUc-glHPOHIcGxowwSS4mywG-pkQ7ds4TD1up0QIegEuakOS3ThC7cj9Dl4_Gso56umXmXGIGa"
                        frameBorder="0"
                        scrolling="auto"
                        allowFullScreen
                        style={{
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            maxWidth: '100%',
                        }}
                    ></iframe>
                </div>
            </section>
        </Layout>
    );
};

export default DesinscriptionSMS;